import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хэрэглэгчийн мэдээлэл, сонордуулга",
  "description": null,
  "author": "OTC help",
  "category": "account-management",
  "date": "2022-03-28T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хэрэглэгчийн мэдээлэл, сонордуулга`}</h2>
    <p>{`Хэрэглэгч дэлгэцийн баруун дээд булан дахь хонхны товчийг дарж арилжаа болон зах зээлийн шинэ мэдээ, мэдэгдэл, зарлалыг харах боломжтой.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      